.tableau-container {
    display: flex;
    width: 100%;
    /*height: 82vh;*/
    justify-content: center;
    align-items: center; /* Añadir para centrar verticalmente */
    padding: 20px;
    overflow: hidden; /* Mantener overflow: hidden; */
}

.tableau-container .tableauPlaceholder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; /* Añadir para centrar verticalmente */
    overflow: hidden; /* Mantener overflow: hidden; */
}

.tableau-container .tableauViz {
    width: 100%;
    height: 100%;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
